<template>
<div>
	<h3>PDF TEST</h3>

	<q-uploader
        url="/api/pdfupload"
        color="green"
        flat
        bordered
        style="max-width: 300px"
      />

     

</div>
</template>
<script>
export default {
	name: 'PdfTest',
}
</script>